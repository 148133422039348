import React, { useEffect, useState } from 'react';
import * as styles from './header.module.scss';
import useResponsive from '@src/utils/useResponsive';
import Logo from '@components/molecules/Logo';
import MenuList from '@components/molecules//MenuList';
import MobileMenuList from '@components/molecules//MobileMenuList';
import LangSwitcher from '@components/molecules/LangSwitcher/LangSwitcher';
import MobileLangSwitcher from '@components/molecules/MobileLangSwitcher/MobileLangSwitcher';
import LoginActions from '@components/molecules/LoginActions/LoginActions';
import MobileLoginActions from '@components/molecules/MobileLoginActions/MobileLoginActions';
import SearchPlayerButton from '@components/molecules/SearchPlayerButton';
import HeaderMenuTooltip from '@components/molecules/HeaderMenuTooltip';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';

import ExcorpLogo from '@src/assets/images/landing/Excorp.svg';
import UnionIcon from '@src/assets/images/landing/Union.svg';
import burger from '@src/assets/images/landing/burger.svg';
import crossIcon from '@src/assets/images/landing/cross-icon.svg';
import BannerTech from '../BannerTech';

import PlayerSearch from '@components/molecules/PlayerSearch';

const Header = ({ onSearchPlayer }) => {
	const intl = useIntl();

	const [isDesktop, isTablet, isMobile] = useResponsive();
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [showHeader, setShowHeader] = useState(false);
	const [showMobilePlayerSearch, setShowMobilePlayerSearch] = useState(false);
	const [headerTooltipVisible, setHeaderTooltipVisible] = useState(false);

	useEffect(() => {
		if (showMobileMenu) {
			document.body.style.overflowY = 'hidden';
		} else {
			document.body.style.overflowY = 'scroll';
		}
	}, [showMobileMenu]);

	useEffect(() => {
		setShowHeader(true);
	}, []);

	return (
		<header className={styles.header}>
			<div className={styles.container}>
				{!isMobile ? (
					<div className={styles.logosContainer}>
						<div className={styles.scopeLogoWrapper}>
							<Logo />
							{!isMobile ? <img src={UnionIcon} alt="Union between logos" /> : ''}
						</div>

						<a href="https://excorp.gg" target="_blank" style={{ display: 'inline-flex' }}>
							<img className={styles.excorpLogoImage} src={ExcorpLogo} alt="EXCORP.GG LOGO" />
						</a>
					</div>
				) : (
					<Logo size="small" />
				)}

				<div className={styles.searchPlayerWrapper}>
					<SearchPlayerButton onClick={onSearchPlayer} />
				</div>

				{!isMobile ? (
					<>
						<div className={styles.menuListWrapper}>
							<MenuList />
						</div>
						<div className={styles.lang}>
							<LangSwitcher />
						</div>
						<div className={styles.burgerButton}>
							{!headerTooltipVisible ? (
								<button
									onClick={() => setHeaderTooltipVisible(true)}
									style={{
										width: '36px',
										height: '36px',
										marginLeft: 'auto',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<img src={burger} alt="Burger menu" />
								</button>
							) : (
								<div className={styles.headerTooltipVisible}>
									<button
										onClick={() => setHeaderTooltipVisible(false)}
										style={{
											width: '36px',
											height: '36px',
											marginLeft: 'auto',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<img src={crossIcon} alt="SCOPE.GG Cross Icon" />
									</button>

									<div className={styles.headerTooltipVisibleContent}>
										<HeaderMenuTooltip
											onClick={() => {
												setHeaderTooltipVisible(false);
												onSearchPlayer();
											}}
										/>
									</div>
								</div>
							)}
						</div>
						<div className={styles.actions}>
							<LoginActions location="Header" />
						</div>
					</>
				) : (
					<>
						<button
							onClick={() => setShowMobileMenu(true)}
							style={{ width: '36px', height: '36px', marginLeft: 'auto' }}
						>
							<img src={burger} alt="Burger menu" />
						</button>
					</>
				)}
				{isMobile ? (
					<aside className={`${styles.mobileMenu}${showMobileMenu ? ' open' : ''}`}>
						<button
							className={styles.mobileMenuClose}
							onClick={() => {
								if (showMobilePlayerSearch) {
									setShowMobilePlayerSearch(false);
								} else {
									setShowMobileMenu(false);
								}
							}}
						>
							<img src={crossIcon} alt="SCOPE.GG Cross Icon" />
						</button>

						{!showMobilePlayerSearch ? (
							<>
								<div className={styles.logosContainer}>
									<Logo />
									<a href="https://excorp.gg" target="_blank">
										<img src={ExcorpLogo} alt="EXCORP.GG LOGO" />
									</a>
								</div>

								<SearchPlayerButton onClick={() => setShowMobilePlayerSearch(true)} />

								<MobileMenuList />

								<div className={styles.mobileLang}>
									<MobileLangSwitcher />
								</div>

								<MobileLoginActions location="Mobile menu" />
							</>
						) : (
							<div className={styles.searchPlayerInputWrapper}>
								<PlayerSearch autoFocus={true} />
							</div>
						)}
					</aside>
				) : null}
			</div>
		</header>
	);
};

export default Header;
